import * as Yup from 'yup';
import isNil from 'lodash/isNil';
import { useFormikContext } from 'formik';
import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { Table } from 'altus-ui-components';
import { EMPTY_LIST } from 'app/app.constants';
import TextField from 'app/components/form/TextFieldV2';
import { RuleForm } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';

const totalWeightSchema = Yup.number().test(
  'total-weight',
  'Total weight needs to be exactly 100%',
  (total) => total === 100,
);

// flatten this list of integrities to only show the categories under parentId
const CategoriesTableWeight = ({
  classes,
  integrities,
  setIntegrities,
  integrityType,
}) => {
  // for total error
  const [error, setError] = useState('');

  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    if (integrityType) {
      setFieldValue([RuleForm.INTEGRITY_TYPE], integrityType);
    }
  }, [integrityType]);

  const validateTotalWeight = useCallback((integrities) => {
    const totalWeight = integrities.reduce(
      (acc, integrity) => acc + (integrity.weight || 0),
      0,
    );

    try {
      totalWeightSchema.validateSync(totalWeight);
      setError(''); // Clear any error if valid
    } catch (validationError) {
      setError(validationError.message); // Set specific error message
    }

    // moved these lines here to solve the Submit button activation issue
    // set formik fields
    setFieldValue([RuleForm.RELATED_INTEGRITIES], integrities);
  }, []);

  const updateWeightValueOnBlur = useCallback(
    (integrityId, newWeight) => {
      // update
      const updatedIntegrities = integrities.map((integrity) =>
        integrity.id === integrityId
          ? { ...integrity, weight: parseFloat(newWeight) || 0 }
          : integrity,
      );
      setIntegrities(updatedIntegrities);
      validateTotalWeight(updatedIntegrities);

      // new integrity weight
      if (isNil(integrityId)) {
        setFieldValue([RuleForm.WEIGHT], newWeight);
      }
    },
    [integrities, setIntegrities, validateTotalWeight, setFieldValue],
  );

  const totalWeight = useMemo(() => {
    return integrities?.reduce(
      (acc, integrity) => acc + (integrity.weight || 0),
      0,
    );
  }, [integrities]);

  useEffect(() => {
    validateTotalWeight(integrities);
  }, [integrities, validateTotalWeight]);

  const columns = useMemo(
    () => [
      {
        xs: 9,
        id: 'name',
        accessor: (integrity) => integrity.title,
      },
      {
        xs: 3,
        id: 'edit',
        Cell: ({ row }) => {
          const integrity = row.original;

          // Local state for input value
          const [localWeight, setLocalWeight] = useState(integrity.weight || 0);

          return (
            <Grid container alignItems="center">
              <Grid
                item
                xs={10}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <TextField
                  label={''}
                  type="number"
                  value={localWeight}
                  onChange={(e) => setLocalWeight(e.target.value)}
                  onBlur={() =>
                    updateWeightValueOnBlur(integrity.id, localWeight)
                  }
                  inputProps={{
                    min: 0,
                    max: 100,
                    step: '0.01', // Allow decimals
                  }}
                  className={classes.root}
                />
              </Grid>
              <Grid item xs={2}>
                <span>%</span> {/* Append percentage sign */}
              </Grid>
            </Grid>
          );
        },
      },
    ],
    [updateWeightValueOnBlur, classes],
  );

  return (
    <Grid container direction="column" spacing={2}>
      {error && (
        <Grid item className={classes.errorText}>
          {error} {`Current total weight ${totalWeight} %`}
        </Grid>
      )}

      {/* Table */}
      <Grid item>
        <Table
          useExpanded
          disableSortBy
          subrowOffset={3}
          items={integrities || EMPTY_LIST}
          columns={columns}
          useGlobalFilter={false}
          noItemsMessage="There are no integrities for this organization."
        />
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={9}>
          <span className={error ? classes.errorText : undefined}>
            Total Weight:
          </span>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'right' }}>
          <span className={error ? classes.errorText : undefined}>
            {totalWeight}%
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[700],
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
});

export default withStyles(styles)(CategoriesTableWeight);
