import { List } from 'immutable';
import * as yup from 'yup';

export const INTEGRITY_MANAGEMENT_ACTIONS = {
  GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES:
    'GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES',
  GET_ALL_INTEGRITY_MANAGEMENT_RULES: 'GET_ALL_INTEGRITY_MANAGEMENT_RULES', // TODO delete
  ADD_NEW_INTEGRITY_MANAGEMENT_RULE: 'ADD_NEW_INTEGRITY_MANAGEMENT_RULE',
  DELETE_INTEGRITY_MANAGEMENT_RULE: 'DELETE_INTEGRITY_MANAGEMENT_RULE',
  ADD_NEW_INTEGRITY_MANAGEMENT_CATEGORY:
    'ADD_NEW_INTEGRITY_MANAGEMENT_CATEGORY',
  DELETE_INTEGRITY_MANAGEMENT_CATEGORY: 'DELETE_INTEGRITY_MANAGEMENT_CATEGORY',
};

// check can we use the same ID
export const RISK_PRINCIPLE_TYPES = {
  LIKELIHOOD_OF_FAILURE: 'LIKELIHOOD OF FAILURE',
  CONSEQUENCE_OF_FAILURE: 'WELL CONSEQUENCE',
};

export const INTEGRITY_TYPE_ENUM = {
  // NONE: 0, // not needed
  RULE: 1,
  CATEGORY: 2,
  RISK_PRINCIPLE: 3,
};

// used for selector when creating new rule or category
export const INTEGRITY_TYPE_SELECTOR = {
  RULE: 1,
  CATEGORY: 2,
};

export const RULE_TYPES_ENUM = {
  RULE_BOOL: 1,
  RULE_DOUBLE: 2,
  RULE_GEOGRAPHY: 3,
  RULE_TIME: 4,
  RULE_PICKLIST: 5,
};

export const BOOLEAN_DATA_VALUES_ENUM = {
  NO: { value: 0, label: 'No' },
  YES: { value: 1, label: 'Yes' },
};

export const INTEGRITY_MANAGEMENT_RULE_TYPES = List([
  { id: '1', code: 'RuleBool', name: 'Data Value (Yes/No)' },
  { id: '2', code: 'RuleDouble', name: 'Data Value (Numeric Value)' },
  { id: '3', code: 'RuleGeography', name: 'Geography' },
  { id: '4', code: 'RuleTime', name: 'Time/Age' },
  { id: '5', code: 'PickListOption', name: 'Picklist option' },
]);

export const INTEGRITY_MANAGEMENT_RULE_DATA_TYPE = List([
  { id: '1', name: 'Yes/No' },
  { id: '2', name: 'Numeric Value' },
]);

export const getRuleTypeString = (ruleType) => {
  return INTEGRITY_MANAGEMENT_RULE_TYPES.find(
    (rule) => Number(rule.id) === ruleType,
  )?.name;
};

export const RuleForm = {
  TITLE: 'title',
  WEIGHT: 'weight',
  SCORE: 'score',
  RULE_TYPE: 'ruleType',
  DEFAULT_VALUE_CHECKED: 'defaultValueRequired',
  VALUE: 'value',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  PARENT_ID: 'parentId',
  PICKLIST_OPTIONS: 'picklistOptions',
  INTEGRITY_TYPE: 'integrityType',
  RELATED_INTEGRITIES: 'relatedIntegrities',
};

export const CategoryForm = {
  TITLE: 'title',
  PARENT_ID: 'parentId',
  WEIGHT: 'weight',
  INTEGRITY_TYPE: 'integrityType',
  RELATED_INTEGRITIES: 'relatedIntegrities',
};

export const IntegrityRulesYupValidator = [
  // validate information
  yup.object().shape({
    [RuleForm.TITLE]: yup.string().required('Rule Title is required'),
    // [RuleForm.PICKLIST_OPTIONS]: yup.array().of(
    //   yup.object().shape({
    //     option: yup.string().required('Option is required'),
    //     score: yup.number().required('Score is required').min(0).max(10),
    //   }),
    // ),
  }),
  // validate location
  yup.object().shape({
    [RuleForm.PARENT_ID]: yup.string().required('Location is required'),
  }),
  // validate weight
  yup.object().shape({
    // TODO we need to check is all in the same category weighting 100 %
    [RuleForm.WEIGHT]: yup
      .number()
      .positive('Weight must be a positive number')
      .required('Weight is required'),
  }),
];

export const IntegrityCategoriesYupValidator = [
  // validate information
  yup.object().shape({
    [CategoryForm.TITLE]: yup.string().required('Title is required'),
  }),
  // validate location
  yup.object().shape({
    [CategoryForm.PARENT_ID]: yup.string().required('Location is required'),
  }),
  // validate weight
  yup.object().shape({
    // we need to check is all in the same category weighting 100 % this will include new category
    [CategoryForm.RELATED_INTEGRITIES]: yup
      .mixed()
      .test('total-weight', 'Total weight needs to be exactly 100%', (list) => {
        // Sum weights in the Immutable.js List
        const totalWeight = list.reduce(
          (sum, item) => sum + (item.weight || 0),
          0,
        );
        return totalWeight === 100;
      }),
  }),
];
