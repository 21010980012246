import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import routePaths from 'app/routePaths';
import { INTEGRITY_MANAGEMENT_ACTIONS } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import integrityManagementService from 'features/settings/integrityManagement/helpers/integrityManagement.service';

export const loadIntegrityManagementIntegrities = () => (dispatch) => {
  const payload =
    integrityManagementService.getIntegrityManagementIntegrities();
  dispatch({
    type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES,
    payload,
  });
  return payload;
};

export const loadIntegrityManagementRules = () => (dispatch) => {
  const payload =
    integrityManagementService.getIntegrityManagementIntegrities();
  dispatch({
    type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_RULES,
    payload,
  });
  return payload;
};

export const deleteIntegrityManagementRule = (ruleId) => (dispatch) => {
  const payload =
    integrityManagementService.deleteIntegrityManagementRule(ruleId);
  dispatch({
    type: INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_RULE,
    confirmationDialog: {
      title: 'Delete Rule',
      confirmButtonText: 'Delete',
      description: 'Are you sure you want to delete this rule?',
    },
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting Rule',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Rule successfully Deleted!',
    },
    payload,
  });
  return payload;
};

export const addNewIntegrityManagementRule =
  (formValues, toggleDrawer) => (dispatch) => {
    const { ...rule } = formValues;

    const payload = integrityManagementService
      .addNewIntegrityManagementRule(rule)
      .then(() => {
        return dispatch(loadIntegrityManagementRules()).then(() => {
          toggleDrawer();
        });
      });

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_RULE,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Adding new Rule',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'New rule successfully added!',
      },
      payload,
    });
  };

export const addNewIntegrityManagementCategory = (formValues) => (dispatch) => {
  const { ...category } = formValues;

  const payload = integrityManagementService
    .addNewIntegrityManagementIntegrity(category)
    .then(() => {
      return dispatch(loadIntegrityManagementRules()).then(() => {
        window.location = routePaths.settings.integrity.root;
      });
    })
    .catch((error) => {
      // Handle the error here, e.g., dispatch an error notification
      dispatch({
        type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_CATEGORY,
        notification: {
          [NOTIFICATION_VARIANTS.ERROR]: `Failed to add new category: ${error.message}`,
        },
      });
      // Optionally log the error to the console
      console.error('Error adding integrity management category:', error);
    });

  dispatch({
    type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_RULE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Adding new Rule',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'New rule successfully added!',
    },
    payload,
  });
};
