import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { BasePage } from 'altus-ui-components';
import { useLocation } from 'react-router-dom';
import AddCategoryContent from './AddCategoryContent';
import AddRuleOrCategoryHeader from '../AddRuleOrCategoryHeader';
import { RISK_PRINCIPLE_TYPES } from '../../helpers/integrityManagement.constants';

const AddCategoryContainer = () => {
  const location = useLocation();
  const { riskPrincipleType } =
    location.state || RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE;

  return (
    <BasePage>
      <Grid item container spacing={2} xs={12} m={2}>
        <Grid container direction="column">
          <Box mt={2} mb={2}>
            <AddRuleOrCategoryHeader title="New Integrity" />
          </Box>
          <AddCategoryContent riskPrincipleType={riskPrincipleType} />
        </Grid>
      </Grid>
    </BasePage>
  );
};

// TODO adjust styles

export default AddCategoryContainer;
