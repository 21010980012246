const integrityIntegritiesFromDTO = ({ ...integrities }) => {
  return {
    ...integrities,
  };
};

const integrityIntegritiesToDTO = ({ ...integrities }) => {
  return {
    ...integrities,
  };
};

const integrityManagementMappers = {
  integrities: {
    from: integrityIntegritiesFromDTO,
    to: integrityIntegritiesToDTO,
  },
};

export default integrityManagementMappers;
