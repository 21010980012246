import { compose } from 'redux';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import CategoryWeightContainer from './CategoryWeightContainer';
import CategoryLocationContainer from './CategoryLocationContainer';
import CategoryInformationContainer from './CategoryInformationContainer';
import {
  addNewIntegrityManagementCategory,
  loadIntegrityManagementRules,
} from 'features/settings/integrityManagement/helpers/integrityManagement.actions';
import {
  CategoryForm as CategoryFormFields,
  RuleForm as RuleFormFields,
  INTEGRITY_MANAGEMENT_ACTIONS,
  INTEGRITY_TYPE_SELECTOR,
  IntegrityRulesYupValidator,
  IntegrityCategoriesYupValidator,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { getIntegrityManagementIntegritiesFromStateByRiskPrinciple } from '../../helpers/integrityManagement.selectors';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import MultiStepForm from 'features/settings/integrityManagement/integrities/MultiStepForm';
import RuleInformationContainer from '../rule/RuleInformationContainer';
import { EMPTY_LIST } from 'app/app.constants';
import {
  hasEmptyIdItem,
  addItemToCategory,
  removeEmptyIdItems,
  getCombinedTopLevelEntries,
} from '../integrities.collections';

// this is a temporary solution to test is it working it will be unified with AddRuleOrCategoryContainer
const AddCategoryContent = ({
  classes,
  filteredIntegrities,
  dispatchLoadIntegrityManagementRules,
}) => {
  const dispatch = useDispatch();

  const [parentId, setParentId] = useState(null);
  const [newCategory, setNewCategory] = useState(null);
  const [integrities, setIntegrities] = useState(EMPTY_LIST);
  const [parentCategoriesAndDetials, setParentCategoriesAndDetails] =
    useState(EMPTY_LIST);
  const [integrityType, setIntegrityType] = useState(null);
  const [validationSchemas, setValidationSchemas] = useState(
    IntegrityRulesYupValidator,
  );

  const initialFormValues = {
    [RuleFormFields.TITLE]: '',
    [RuleFormFields.PARENT_ID]: '',
    [RuleFormFields.WEIGHT]: 0,
    [RuleFormFields.SCORE]: null,
    [RuleFormFields.RULE_TYPE]: '',
    [RuleFormFields.DEFAULT_VALUE_CHECKED]: true,
    [RuleFormFields.VALUE]: null,
    [RuleFormFields.PICKLIST_OPTIONS]: [{ option: '', score: '' }],
    [RuleFormFields.RELATED_INTEGRITIES]: [],
    [RuleFormFields.INTEGRITY_TYPE]: INTEGRITY_TYPE_SELECTOR.RULE,
  };

  useEffect(() => {
    if (integrityType === INTEGRITY_TYPE_SELECTOR.RULE) {
      setValidationSchemas(IntegrityRulesYupValidator);
    } else if (integrityType === INTEGRITY_TYPE_SELECTOR.CATEGORY) {
      setValidationSchemas(IntegrityCategoriesYupValidator);
    }
    // eslint-disable-next-line
  }, [integrityType]);

  // sooo we will use empty id to dinstinguish for now we can change the approach or generate new one
  const setParentIdAndFilterParentCategories = (newParentId) => {
    // set states
    setParentId(newParentId);

    // if it is not null/undefined add new item
    if (!isNil(newParentId)) {
      const newIntegrityObj = {
        id: null,
        [CategoryFormFields.TITLE]: newCategory[CategoryFormFields.TITLE],
        [CategoryFormFields.PARENT_ID]: newParentId,
        [CategoryFormFields.WEIGHT]: 0,
      };
      setNewCategory(newIntegrityObj);

      var newIntegrities = integrities.map((item) => cloneDeep(item));

      // first add new item i.e. category on specified position
      addItemToCategory(newIntegrities, newParentId, newIntegrityObj);

      // then flatten the data we want to send to weight table
      let adjustedCategoriesAndDetails = getCombinedTopLevelEntries(
        newIntegrities,
        newParentId,
      );
      setParentCategoriesAndDetails(adjustedCategoriesAndDetails);
    } else {
      if (hasEmptyIdItem(integrities)) {
        newIntegrities = removeEmptyIdItems(integrities);
      }
      setParentCategoriesAndDetails(EMPTY_LIST);
      setNewCategory(null);
    }
    setIntegrities(newIntegrities);
  };

  useEffect(() => {
    dispatchLoadIntegrityManagementRules();
  }, [dispatchLoadIntegrityManagementRules]);

  useEffect(() => {
    if (filteredIntegrities) {
      setIntegrities(filteredIntegrities);
    }
  }, [filteredIntegrities]);

  const handleTitleChange = (newTitle) => {
    setNewCategory({
      id: null,
      [CategoryFormFields.TITLE]: newTitle,
      [CategoryFormFields.PARENT_ID]: '',
      [CategoryFormFields.WEIGHT]: 0,
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        if (integrityType === INTEGRITY_TYPE_SELECTOR.RULE) {
          return <RuleInformationContainer onTitleChange={handleTitleChange} />;
        } else if (integrityType === INTEGRITY_TYPE_SELECTOR.CATEGORY) {
          return (
            <CategoryInformationContainer onTitleChange={handleTitleChange} />
          );
        }
        break;
      case 1:
        return (
          <CategoryLocationContainer
            integrities={integrities}
            parentId={parentId}
            setParentId={setParentIdAndFilterParentCategories}
            integrityType={integrityType}
          />
        );
      case 2:
        return (
          <CategoryWeightContainer
            integrities={parentCategoriesAndDetials}
            setIntegrities={setParentCategoriesAndDetails}
            integrityType={integrityType}
          />
        );
      default:
        return null;
    }
  };

  // we need to send a list in the end
  const handleSubmitForm = (formValues) => {
    if (parentId) {
      // check is this the first category to be added on this level
      if (
        parentCategoriesAndDetials ||
        parentCategoriesAndDetials !== EMPTY_LIST
      ) {
        // remove the id null one here
        const relatedIntegrities = parentCategoriesAndDetials
          .filter((item) => !isNil(item.id))
          .map((item) => ({
            id: item.id,
            weight: String(item.weight), // TODO check does it must be string
          }))
          .toJS();
        // returnn only id and weight
        formValues[CategoryFormFields.RELATED_INTEGRITIES] = relatedIntegrities;
      }
      dispatch(addNewIntegrityManagementCategory(formValues));
    }
  };

  return (
    <MultiStepForm
      initialValues={initialFormValues}
      validationSchemas={validationSchemas}
      onSubmit={handleSubmitForm}
      renderStepContent={getStepContent}
      classes={classes}
      integrityType={integrityType}
      setIntegrityType={setIntegrityType}
    />
  );
};

const styles = (theme) => ({
  gridBackground: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
  gridBackgroundMobile: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  backButtonIcon: {
    color: theme.palette.primary.newLight,
    width: '90%',
    height: '90%',
  },
  statusHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  containedButton: {
    minHeight: theme.spacing(8),
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(2),
  },
  containedButtonGrey: {
    minHeight: theme.spacing(8),
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.lighter,
    color: theme.palette.secondary.dark,
  },
  stepper: {
    backgroundColor: '#303030', // TODO check what color is this in theme
  },
  bottomButtonContainer: {
    paddingTop: theme.spacing(4),
  },
});

export default compose(
  connect(
    (state, { riskPrincipleType }) => ({
      filteredIntegrities:
        getIntegrityManagementIntegritiesFromStateByRiskPrinciple(
          state,
          riskPrincipleType,
        ),
      dataState: getSummarizedDataStateFromState(
        state,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES,
      ),
    }),
    {
      dispatchLoadIntegrityManagementRules: loadIntegrityManagementRules,
    },
  ),
  withStyles(styles),
)(AddCategoryContent);
