import { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid } from '@material-ui/core';

import { useToggle } from 'altus-hooks';
import { BasePage } from 'altus-ui-components';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { getIntegrityManagementIntegritiesFromStateByRiskPrinciple } from 'features/settings/integrityManagement/helpers/integrityManagement.selectors';
import {
  INTEGRITY_MANAGEMENT_ACTIONS,
  INTEGRITY_TYPE_ENUM,
  RISK_PRINCIPLE_TYPES,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import {
  deleteIntegrityManagementRule,
  loadIntegrityManagementRules,
} from 'features/settings/integrityManagement/helpers/integrityManagement.actions';
import IntegrityActions from 'features/settings/integrityManagement/rules/IntegrityActions';
import AddRuleDrawer from 'features/settings/integrityManagement/rules/AddRuleDrawer';
import IntegritiesTable from '../rules/IntegritiesTable';

const LikelihoodOfFailureContainer = ({
  riskPrincipleType = RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE,
  classes,
  dataState,
  filteredIntegrities,
  dispatchLoadIntegrityManagementRules,
  // eslint-disable-next-line
  dispatchDeleteIntegrityManagementRule,
}) => {
  const [isOpen, toggle] = useToggle(); // TODO delete when not needed

  useEffect(() => {
    dispatchLoadIntegrityManagementRules();
  }, [dispatchLoadIntegrityManagementRules]);

  // we skip this for now
  // eslint-disable-next-line
  const deleteIntegrity = (integrityId, integrityType) => {
    //dispatchDeleteIntegrityManagementRule(integrityId);
  };

  return (
    <BasePage dataState={dataState}>
      <Grid item container spacing={2} className={classes.tableContainer}>
        <IntegrityActions
          toggleDrawer={toggle}
          riskPrincipleType={riskPrincipleType}
        />
        <IntegritiesTable
          integrities={filteredIntegrities}
          deleteIntegrity={deleteIntegrity}
          riskPrincipleType={riskPrincipleType}
        />
        <AddRuleDrawer isOpen={isOpen} toggleDrawer={toggle} />
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  tableContainer: {
    paddingTop: theme.spacing(2),
  },
});

export default compose(
  connect(
    // for RiskPrincipleContent
    // (state, { riskPrincipleType })
    (state) => ({
      filteredIntegrities:
        getIntegrityManagementIntegritiesFromStateByRiskPrinciple(
          state,
          RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE,
        ),
      dataState: getSummarizedDataStateFromState(
        state,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_RULES,
        INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_RULE,
      ),
    }),
    {
      dispatchLoadIntegrityManagementRules: loadIntegrityManagementRules,
      dispatchDeleteIntegrityManagementRule: deleteIntegrityManagementRule,
    },
  ),
  withStyles(styles),
)(LikelihoodOfFailureContainer);
